import { zodResolver } from '@hookform/resolvers/zod';
import {
    Button,
    Col,
    Row,
    Switch,
    Typography,
    notification
} from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { z } from 'zod';
import { checkTcNum } from '../../helpers/helpers';
import { useLazyQuery } from '../../hooks/useAxios';
import MyInput from '../MyInput';
import MyModal from '../MyModal';
import MyPhoneInput from '../MyPhoneInput/MyPhoneInput';
import MySelect from '../MySelect';

const { Text } = Typography;
const defaultValue = {
    firstname:'',
    lastname:'',
    email:'',
    tckno:'',
    work_email:'',
    work_phonenumber:'',
    phone_number:'',
    entry_date: dayjs(),
    role_id: 11,
    branch_id: 1,
    optional: false,
    department_id:7
};

const defaultSchema = {
    firstname: z.string().nonempty({ message: <Trans i18nKey="Workers.createPersonalModal.requiredFirstName" /> }),
    lastname: z.string().nonempty({ message: <Trans i18nKey="Workers.createPersonalModal.requiredLastName" /> }),
    entry_date: z.coerce.date(),
    tckno: z
        .string()
        .nonempty({ message: <Trans i18nKey="Workers.createPersonalModal.requiredTcNo" /> })
        .refine((value) => checkTcNum(value), {
            message: 'Geçerli bir TC Kimlik Numarası giriniz'
        }),
    email: z.string().email({ message: <Trans i18nKey="Workers.createPersonalModal.requiredEmail" /> }),
    role_id: z.number().int(),
    branch_id: z.number().int(),
    department_id:z.number().int(),
};

const optionalFalseSchema = z.object({
    ...defaultSchema,
    phone_number: z
        .string()
        .nullable()
        .optional()
        .refine((value) => !value || isValidPhoneNumber(value), {
            message: 'Geçerli bir telefon numarası giriniz'
        }),
    work_phonenumber: z
        .string()
        .nullable()
        .optional()
        .refine((value) => !value || isValidPhoneNumber(value), {
            message: 'Geçerli bir iş telefon numarası giriniz'
        }),
    work_email: z.string().email({ message: <Trans i18nKey="Workers.createPersonalModal.requiredEmail" /> }).optional(),
    optional: z.literal(false)
});

const optionalTrueSchema = z.object({
    ...defaultSchema,
    phone_number: z
        .string()
        .nullable()
        .refine((value) => !value || isValidPhoneNumber(value), {
            message: 'Geçerli bir telefon numarası giriniz'
        }),
    work_phonenumber: z
        .string()
        .nullable()
        .refine((value) => !value || isValidPhoneNumber(value), {
            message: 'Geçerli bir iş telefon numarası giriniz'
        }),
    work_email: z.string().email(),
    optional: z.literal(true)
});

const schema = z.union([optionalFalseSchema, optionalTrueSchema]);

const CreatePersonalModal = ({ open, setOpen, handleRefreshTable }) => {
    const { t } = useTranslation();
    const [selectedDepartments, setSelectedDepartments] = useState([]);

    const form = useForm({
        resolver: zodResolver(schema),
        defaultValues: {...defaultValue}
    });
    const { handleSubmit } = form;
    const { refetch:createRegister, data, error, loading,  } = useLazyQuery('/auth/register', {
        method: 'POST'
    });
    const { data:getDepartmansData, refetch:getDepartmans } = useLazyQuery('/global/departments', {
        method: 'GET'
    });
    const {
        data: rolesData,
        called,
        refetch: rolesRefetch
    } = useLazyQuery('/global/roles');
    const {
        data: branchesData,
        called: branchesCalled,
        refetch: branchesRefetch
    } = useLazyQuery('/global/branches');
    useEffect(()=> {
        if(open){
            getDepartmans();
        }
    },[open])

    // useEffect(() => {
    //     if (getDepartmansData?.data?.length > 6) {
    //         const defaultDepartmentId = getDepartmansData.data[6]?.department_id;
    //         if (defaultDepartmentId) {
    //             setSelectedDepartments([defaultDepartmentId]);
    //             form.setValue('department_id', defaultDepartmentId); // Set the default value in the form
    //         }
    //     }
    // }, [getDepartmansData]);

    const departmants = getDepartmansData?.data;
    const handleSelectChange = (value) => {
        setSelectedDepartments(value);
    };
  
    
    useEffect(() => {
        if (open && !called) {
            rolesRefetch();
        }
        if (open && !branchesCalled) {
            branchesRefetch();
        }
    }, [open, !called]);

    const roles = rolesData?.data || [];
    const branches = branchesData?.data || [];

    const onSubmit = async (formData) => {
        try {
            await createRegister({ data:{
                ...formData
            } });
            notification.success({
                message: 'Personal Created'
            });
            handleRefreshTable();
            setOpen(false);
        } catch (error) {
            notification.error({
                message: error?.errorCode
            });
        }
    };

    return (
        <MyModal
            isOpen={open}
            setIsOpen={setOpen}
            title={t('Workers.createPersonal')}
            handleSubmit={handleSubmit(onSubmit)}
            confirmLoading={loading}
            formMethods={form}
            footer={
                <Row>
                    <Col
                        flex={1}
                        style={{
                            textAlign: 'start'
                        }}
                    >
                        <Switch
                            onChange={(checked) =>
                                form.setValue('optional', checked)
                            }
                            checked={form.watch('optional')}
                        ></Switch>
                        <Text
                            type="text"
                            onClick={() =>
                                form.setValue(
                                    'optional',
                                    !form.watch('optional')
                                )
                            }
                            style={{
                                cursor: 'pointer',
                                marginLeft: '10px'
                            }}
                        >
                            Opsiyonel Alanları Göster
                        </Text>
                    </Col>
                    <Col flex="auto">
                        <Button
                            loading={loading}
                            onClick={() => setOpen(false)}
                        >
                            {t('Workers.createPersonalModal.cancel')}
                        </Button>
                        <Button
                            style={{
                                marginLeft: '10px'
                            }}
                            type="primary"
                            onClick={handleSubmit(onSubmit)}
                            loading={loading}
                        >
                            {t('Workers.createPersonalModal.create')}
                        </Button>
                    </Col>
                </Row>
            }
        >
            <Col span={12}>
                <MyInput
                    name="firstname"
                    label={t('Workers.createPersonalModal.first_name')}
                />
            </Col>
            <Col span={12}>
                <MyInput
                    name="lastname"
                    label={t('Workers.createPersonalModal.last_name')}
                />
            </Col>
            {/* <Col span={12}>
                <MyInput
                    name="email"
                    label={t('Workers.createPersonalModal.email')}
                />
            </Col>
            <Col span={12}>
                <MyPhoneInput
                    name="phone"
                    label={t('Workers.createPersonalModal.phone')}
                />
            </Col> */}
            {/* <Col span={12}>
                <MyInput
                    name="hire_date"
                    label={t('Workers.createPersonalModal.hire_date')}
                    type="date"
                />
            </Col>*/}
            <Col span={12}>
                <MySelect
                    name="department_id"
                    label={t('Workers.createPersonalModal.department_id')}
                  
                    options={departmants?.map(department => ({
                label: department.department_name,
                value: department.department_id
                
                    }))}
                    placeholder="Select a department"
                    onChange={handleSelectChange}
                />
            </Col>{/*
            <Col span={12}>
                <MySelect
                    name="job_id"
                    label={t('Workers.createPersonalModal.job_id')}
                    options={[
                        { value: '1', label: 'HR' },
                        { value: '2', label: 'IT' },
                        { value: '3', label: 'Finance' },
                        { value: '4', label: 'Marketing' }
                    ]}
                    placeholder="Select a job"
                />
            </Col> */}
            <Col span={12}>
                <MyInput
                    name="entry_date"
                    label="İşe Giriş Tarihi"
                    type="date"
                />
            </Col>
            <Col span={12}>
                <MyInput name="tckno" label="TC Kimlik No" />
            </Col>
            <Col span={12}>
                <MySelect
                    name="role_id"
                    label="Unvan"
                    options={roles.map((item) => ({
                        value: item.role_id,
                        label: item.role_tr_name
                    }))}
                />
            </Col>
            <Col span={12}>
                <MySelect
                    name="branch_id"
                    label="Şube"
                    options={branches.map((item) => ({
                        value: item.branch_id,
                        label: item.name
                    }))}
                />
            </Col>
            <Col span={12}>
                <MyInput name="email" label="E-posta" />
            </Col>
            {form.watch('optional') && (
                <>
                    <Col span={24}>
                        <MyInput name="work_email" label="İş E-posta" />
                    </Col>
                    <Col span={12}>
                        <MyPhoneInput
                            name={'phone_number'}
                            label={'Telefon Numarası'}
                        />
                    </Col>
                    <Col span={12}>
                        <MyPhoneInput
                            name={'work_phonenumber'}
                            label={'İş Telefon Numarası'}
                        />
                    </Col>
                </>
            )}
            {/* <Col span={24}>
                <MyCheckbox
                    name="isSendEmail"
                // label="E-posta Gönder"
                >
                    Çalışanın bilgilerini e-postası gönderilsin mi?{' '}
                    <Tooltip
                        placement="top"
                        title={`Calisana sisteme eklendigine dair bir hos geldin e-postasi gönderilecektir. Bu e-posta da sisteme giris parolasini olusturmasi için gerekli olan baglanti mevcuttur.`}
                    >
                        <Button
                            shape="circle"
                            type="text"
                            icon={<InfoCircleOutlined style={{
                                fontSize: '16px'
                            }} />}

                        ></Button>
                    </Tooltip>
                </MyCheckbox>
            </Col> */}
        </MyModal>
    );
};

export default CreatePersonalModal;
