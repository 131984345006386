import { UploadOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, notification, Select, Typography, Upload } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useLazyQuery } from '../../hooks/useAxios';
import MyInput from '../MyInput/MyInput';

const { Text } = Typography;

const DeleteConfirm = ({ itemName, id, itemType, visible, onCancel, onConfirm }) => {
  const { t } = useTranslation();
  const [fileList, setFileList] = useState([]);
  const [reasons, setReasons] = useState([]);
  const [confirmationVisible, setConfirmationVisible] = useState(false);
  const methods = useForm();
  const { handleSubmit, reset, control, setValue, formState: { isValid } } = useForm({
    mode: 'onChange',
    defaultValues: {
      user_id: id,
      departure_reason_id: '',
      departure_date: null,
      departure_description: '',
      departure_file: null,
    },
  });

  const { refetch: deleteQuery } = useLazyQuery(`/user/work/departure`, { method: 'post' });
  const { refetch: fetchReasons } = useLazyQuery(`/global/departure-reasons`, { method: 'get' });

  useEffect(() => {
    if (id) {
      reset({
        user_id: id,
        departure_reason_id: '',
        departure_date: null,
        departure_description: '',
        departure_file: null,
      });
    }
  }, [id, reset]);

  useEffect(() => {
    const loadReasons = async () => {
      try {
        const response = await fetchReasons();
        setReasons(response?.data); // Ensure correct data access
      } catch (error) {
        console.log('Error fetching departure reasons', error);
      }
    };
    loadReasons();
  }, [fetchReasons]);

  const handleFileChange = ({ fileList: newFileList }) => setFileList(newFileList);
  const onSubmit = async (formData) => {
    try {
      const formDataToSend = new FormData();

      Object.keys(formData).forEach((key) => {
        if (key === 'departure_file' && fileList.length) {
          formDataToSend.append('departure_file', fileList[0].originFileObj);
        } else {
          formDataToSend.append(key, formData[key]);
        }
      });

      await deleteQuery({ data: formDataToSend });

      onConfirm(); 
      onCancel(); 
      notification.success({
        message: t(`Salaries.delete_success`)
    });
        } catch (error) {
      console.log('Error submitting form', error);
    }
  };

  const handleConfirm = () => {
    setConfirmationVisible(false);
    onConfirm();
  };

  return (
    <FormProvider {...methods}>

    <>
      <Modal
        title={`${itemName} ${t(`Confirm.${itemType}_delete`)}`}
       visible={visible} 
        onCancel={onCancel}
        onOk={handleConfirm}
        footer={[
          <Button key="back" onClick={onCancel}>
            <Trans i18nKey="Cancel" />
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleSubmit(onSubmit)}
            disabled={!isValid} 
          >
            <Trans i18nKey="Submit" />
          </Button>,
        ]}
      >
        <Form layout="vertical" style={{ marginTop: 16 }}>
          <Form.Item
            label={<Trans i18nKey="Confirm.terminationReason" />}
            rules={[{ required: true, message: <Trans i18nKey="Confirm.reasonRequired" /> }]}
          >
            <Controller
              name="departure_reason_id"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  {...field}
                  placeholder={<Trans i18nKey="Confirm.selectReason" />}
                >
                  {reasons && reasons.map((reason) => (
                    <Select.Option key={reason.id} value={reason.id}>
                      {reason.departure_reason}
                    </Select.Option>
                  ))}
                </Select>
              )}
            />
          </Form.Item>

          <Form.Item
            label={<Trans i18nKey="Confirm.departure_date" />}
            rules={[{ required: true, message: <Trans i18nKey="Confirm.dateRequired" /> }]}
          >
           <Controller
              name="departure_date"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                // <MyInput
                //   {...field}
                //   type="date"
                //   disabledDate={(current) => current && current < moment().startOf('day').subtract(1, 'day')}
                //   onChange={(date) => field.onChange(date ? date.format('YYYY-MM-DD') : null)}
                //   value={field.value ? setValue(field.value, 'YYYY-MM-DD') : null}
                // />
                <MyInput
                  name="departure_date"
                  type="date"
                  onChange={(date) => field.onChange(date ? date.format('YYYY-MM-DD') : null)}
                  disabledDate={(current) => current && current < moment().startOf('day')}
            />
              )}
            />        
          </Form.Item>
         
              <Form.Item
            label={<Trans i18nKey="Confirm.terminationExplanation" />}
          >
            <Controller
              name="departure_description"
              control={control}
              render={({ field }) => (
                <Input.TextArea
                  {...field}
                  placeholder={t("Confirm.explanationPlaceholder")}
                  rows={4}
                />
              )}
            />
          </Form.Item>

          <Form.Item
            label={<Trans i18nKey="Confirm.uploadFile" />}
          >
            <Upload
              fileList={fileList}
              onChange={handleFileChange}
              beforeUpload={() => false}
            >
              <Button icon={<UploadOutlined />}>
                <Trans i18nKey="Confirm.upload" />
              </Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>

      
    </>
    </FormProvider>

  );
};

export default DeleteConfirm;
