import { Col, Row, Tag } from 'antd'
import dayjs from 'dayjs'
import React from 'react'
import MyTable from '../../components/MyTable/MyTable'

const initialColumns = [
    {
        title: 'Durum',
        dataIndex: 'status',
        key: 'status',
        filters:[
            { text:'Bekliyor',value: 1},
            {text:'Onaylandı', value: 2},
            {text:'Reddedildi', value: 3},
        ],
        onFilter: (value,record) => record.status === value,
        render: (text)=> (
            <span>
        {{
            1: <Tag color={'warning'}>{'Bekliyor'}</Tag>,
            2: <Tag color={'success'}>{'Onaylandı'}</Tag>,
            3: <Tag color={'error'}>{'Reddedildi'}</Tag>
            }[text]}
            </span>
        )
    },
    {
        title: 'Başlangıç Tarihi',
        dataIndex: 'start_date',
        key: 'start_date',
        sorter: true,
        render: (text) => {
            return text && dayjs(text).format('DD MMM YYYY')
        }
    },
    {
        title: 'Bitiş Tarihi',
        dataIndex: 'end_date',
        key: 'end_date',
        sorter: true,
        render: (text) => {
            return text && dayjs(text).format('DD MMM YYYY')
        }
    },
    {
        title: 'İzin Türü',
        dataIndex: 'reason_tr',
        key: 'reason_tr',
        sorter: true
    },
    {
        title: 'İzin Açıklaması',
        dataIndex: 'reason',
        key: 'reason',
        sorter: true
    },
    {
        title: 'İşlem Tarihi',
        dataIndex: 'last_action_at  ',
        key: 'last_action_at  ',
        render: (text,
            record
        ) => <span>
                {
                    dayjs(text).format('DD MMMM YYYY')
                }
            </span>
    },
  
]

const Leave = ({ userId }) => {
    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <MyTable
                    url={'/leave/list?user_id=' + userId}
                    initialColumns={initialColumns}
                ></MyTable>
            </Col>
        </Row>
    )
}

export default Leave