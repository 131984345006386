import { zodResolver } from '@hookform/resolvers/zod';
import { Col } from 'antd';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import MyInput from '../../../../components/MyInput';
import MySelect from '../../../../components/MySelect';
import { useLazyQuery } from '../../../../hooks/useAxios';
import ChangeModal from '../ChangeModal/ChangeModal';

const schema = z.object({
    address: z.string().min(3).max(255).optional().nullable(),
    address2: z.string().min(3).max(255).optional(),
    city_id: z.number().int().optional().nullable(),
    district_id: z.number().int().optional().nullable(),
    country_id: z.number().int().optional().nullable(),
    postal_code: z.string().length(5).optional(),
        // home_phone: z.string().optional().refine(isValidPhoneNumber, {
    //     message: 'Geçersiz telefon numarası'
    // })
});

const AddressModal = ({ isOpen, setIsOpen, data }) => {
    const { t } = useTranslation();
    const [countries, setCountries] = useState([]);
    const [cities, setCities] = useState([]);
    const [districts, setDistricts] = useState([]);

    const { control, watch, setValue, reset } = useForm({
        defaultValues: {
            country_id: data?.country_id || null,
            city_id: data?.city_id || null,
            district_id: data?.district_id || null,
            address: data?.address || '',
            address2: data?.address2 || '',
            postal_code: data?.postal_code || '',
        },
        resolver: zodResolver(schema)
    });
    const countryId = watch('country_id');
    const cityId = watch('city_id');

    const { refetch: fetchCountries } = useLazyQuery('/global/countries');
    const { refetch: fetchCities } = useLazyQuery(`/global/cities/${countryId}`);
    const { refetch: fetchDistricts } = useLazyQuery(`/global/districts/${cityId}`);

    console.log(data,"data");

    useEffect(() => {
        if (isOpen) {
            reset({
                country_id: data?.country_id || null,
                city_id: data?.city_id || null,
                district_id: data?.district_id || null,
                address: data?.address || '',
                address2: data?.address2 || '',
                postal_code: data?.postal_code || '',
            });
        }
    }, [data, isOpen, reset]);

    useEffect(() => {
        const getCountries = async () => {
            const result = await fetchCountries();
            if (result) {
                setCountries(result);
            } else {
                console.error('Ülkeler getirilemedi.');
            }
        };
        getCountries();
    }, [fetchCountries]);

    useEffect(() => {
        if (countryId) {
            const getCities = async () => {
                const result = await fetchCities();
                if (result) {
                    setCities(result);
                } else {
                    console.error('Şehirler getirilemedi.');
                }
            };
            getCities();
        }
    }, [countryId, fetchCities]);

    useEffect(() => {
        if (cityId) {
            const getDistricts = async () => {
                const result = await fetchDistricts();
                if (result) {
                    setDistricts(result);
                } else {
                    console.error('İlçeler getirilemedi.');
                }
            };
            getDistricts();
        }
    }, [cityId, fetchDistricts]);

    useEffect(() => {
        if (isOpen && data?.country_id) {
            fetchCities().then(result => {
                if (Array.isArray(result)) {
                    setCities(result);
                }
            });
        }

        if (isOpen && data?.city_id) {
            fetchDistricts().then(result => {
                if (Array.isArray(result)) {
                    setDistricts(result);
                }
            });
        }
    }, [isOpen, data, fetchCities, fetchDistricts]);

    return (
        <ChangeModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            data={data}
            schema={schema}
            success="Adres bilgileriniz başarıyla güncellendi"
            error="Adres bilgileriniz güncellenirken bir hata oluştu"
            title={t('citizenshipInformation')}
        >
            <Col span={24}>
                <Controller
                    name="address"
                    control={control}
                    render={({ field }) => (
                        <MyInput
                            {...field}
                            label={t('address')}
                            placeholder="Adres giriniz"
                        />
                    )}
                />
            </Col>
            <Col span={24}>
                <Controller
                    name="address2"
                    label={t('address2')}
                    control={control}
                    render={({ field }) => (
                        <MyInput
                            {...field}
                            label={t('address2')}
                            placeholder="Adres 2 giriniz"
                        />
                    )}
                />
            </Col>
            <Col span={12}>
                <Controller
                    name="country_id"
                    label={t('country_id')}
                    control={control}
                    render={({ field }) => (
                        <MySelect
                            {...field}
                            label={t('country')}
                            options={countries.map(country => ({
                                label: country.country_name,
                                value: country.country_id
                            }))}
                        />
                    )}
                />
            </Col>
            <Col span={12}>
                <Controller
                    name="city_id"
                    label={t('city_id')}
                    control={control}
                    render={({ field }) => (
                        <MySelect
                            {...field}
                            label={t('city')}
                            options={cities.map(city => ({
                                label: city.city_name,
                                value: city.city_id
                            }))}
                        />
                    )}
                />
            </Col>
            <Col span={12}>
                <Controller
                    name="district_id"
                    label={t('district_id')}
                    control={control}
                    render={({ field }) => (
                        <MySelect
                            {...field}
                            label={t('district')}
                            options={districts.map(district => ({
                                label: district.district_name,
                                value: district.district_id
                            }))}
                        />
                    )}
                />
            </Col>
            <Col span={12}>
                <Controller
                    name="postal_code"
                    control={control}
                    render={({ field }) => (
                        <MyInput
                            {...field}
                            label="Posta Kodu"
                            placeholder="Posta Kodu giriniz"
                            type="text"
                        />
                    )}
                />
            </Col>
                        {/* <Col span={12}>
                <Controller
                    name="home_phone"
                    control={control}
                    render={({ field }) => (
                        <MyPhoneInput {...field} label="Telefon" />
                    )}
                />
            </Col> */}
        </ChangeModal>
    );
};
export default AddressModal;
