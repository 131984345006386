import { Col, Typography } from 'antd';
import React from 'react';

import DataCard from '../DataCard/DataCard';
import AddressModal from './AddressModal';

const { Text } = Typography;

const Address = ({ data }) => {

    console.log(data,"address")
    
    return (
        <DataCard title="Adres" Extra={true} data={data} Modal={AddressModal}>
            <Col span={24}>
                <Text strong>Adres:</Text> {data?.address || '—'}
            </Col>
            {/* <Col span={24}>
                <Text strong>Adres Devam:</Text> {data?.address2 || '—'}
            </Col> */}
            <Col span={12}>
                <Text strong>Şehir:</Text> {data?.city_name || '—'}
            </Col>
            <Col span={12}>
                <Text strong>Ülke:</Text> {data?.country_name || '—'}
            </Col>
            <Col span={12}>
                <Text strong>Posta Kodu:</Text> {data?.postal_code || '—'}
            </Col>
            <Col span={12}>
                <Text strong>İlçe:</Text> {data?.district_name || '—'}
            </Col>
        </DataCard>
    );
};

export default Address;
