import {
    Avatar,
    Card,
    Col,
    Divider,
    Flex,
    List,
    Row,
    Typography
} from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useLazyQuery } from '../../hooks/useAxios';
import Contact from './components/Contact/Contact';

const items = [
    {
        key: '1',
        label: <Typography.Text type="danger">Atamayı Kaldır</Typography.Text>
    }
];

const { Title, Text } = Typography;

const formatDifference = ({ years, months, days }) => {
    if (years > 0) {
        return `${years} yıl ${months} ay ${days} gün`;
    } else if (months > 0) {
        return `${months} ay ${days} gün`;
    } else {
        return `${days} gün`;
    }
};

const calculateDifference = (start, end) => {
    const years = end.diff(start, 'year');
    start = start.add(years, 'year');

    const months = end.diff(start, 'month');
    start = start.add(months, 'month');

    const days = end.diff(start, 'day');

    return { years, months, days };
};

const Profile = ({ data }) => {
    const startDate = dayjs(data.entry_date); // İşe başlama tarihi
    const endDate = dayjs(); // Bugünün tarihi
    const difference = calculateDifference(startDate, endDate);
    const formattedDiff = formatDifference(difference);
    const [subordinates,setSubordinates] = useState([]);
    const [superiors, setSuperiors] = useState([]);

    const navigate = useNavigate();

    const { refetch: fetchHierarchy } = useLazyQuery(`/user/manager/person/${data.user_id}`);

  useEffect(() => {
    fetchHierarchy();
  }, [fetchHierarchy]);

  useEffect(() => {
    const fetchData = async () => {
        try {
            const response = await fetchHierarchy();
            if (response?.result === 'success') {
                setSubordinates(response?.data?.subordinates);
                setSuperiors(response?.data?.superiors);
            }
        } catch (error) {
            console.error("Error fetching hierarchy data:", error);
            
        }
    };

    fetchData();
}, [fetchHierarchy]);
    const handleNavigation = (key,value) =>{
        navigate( `/workers/?${key}=${value}`);
    }
    console.log(superiors,"superiors")
    
    return (
        <>
        <title>
            {data.firstname + " " + data.lastname}
        </title>
            <Row gutter={16}>
                <Col lg={14} xs={24}>
                    <Row gutter={[16, 16]}>
                        <Card>
                            <Flex justify="space-between">
                                <Col>
                                    <Title level={4}>
                                        {data.firstname +
                                            ' ' +
                                            data?.lastname}
                                    </Title>
                                    <Text>{data.title_tr_name}</Text>
                                    <br />
                                    <Text type="secondary">
                                        {data.department_tr_name}
                                    </Text>
                                </Col>
                                <Col>
                                    <Avatar size={128} shape="square">
                                        {data.avatarUrl ? (
                                            <img
                                                src={data.avatarUrl}
                                                alt={data.fullName}
                                            />
                                        ) : (
                                                data.firstname.charAt(0) +
                                                data.lastname.charAt(0)
                                        )}
                                    </Avatar>
                                </Col>
                                
                            </Flex>
                            <Divider />
                            <Row gutter={[16, 16]}>
                                <Col span={12}>
                                    <Text strong>İşe Başlama Tarihi:</Text>
                                    {dayjs(data.entry_date).format(
                                        'DD MMM YYYY'
                                    )}
                                </Col>
                                <Col span={12}>
                                    <Text strong>Sözleşme Türü:</Text>{' '}
                                    {data.contractType || '—'}
                                </Col>
                                <Col span={12}>
                                    <Text strong>Çalışma Süresi:</Text>{' '}
                                    {formattedDiff}
                                </Col>
                                <Col span={12}>
                                    <Text strong>Sözleşme Bitiş Tarihi:</Text>{' '}
                                    {data.contractEndDate || '—'}
                                </Col>
                                <Divider />
                                <Col span={12}>
                                    <Text strong>Pozisyon Başlama Tarihi:</Text>{' '}
                                    {data.positionStartDate}
                                </Col>
                                <Col span={12}>
                                    <Text strong>Çalışma Şekli:</Text>{' '}
                                    {data.employmentType}
                                </Col>
                                {/* {data.unit
                .sort(
                    (a, b) =>
                        a.companyUnit.sequence -
                        b.companyUnit.sequence
                )
                .map((unit, index) => (
                    <Col span={12} key={index}>
                        <Text strong>{unit.companyUnit.name}:</Text>{' '}
                        {unit.name}
                    </Col>
                ))} */}
                            </Row>
                            <Divider />
                            {/* Department, Branch, and Title Info */}
                            <Row gutter={[16, 16]}>
                                <Col span={12}>
                                    <Text
                                        strong
                                        onClick={() =>
                                            handleNavigation('t_department_id', data.department_id)
                                        }
                                        
                                    >
                                        Departman: 
                                        <span style={{ cursor: 'pointer', color: '#1890ff' ,paddingLeft:'1px'}}>
                                          {data.department_tr_name}
                                        </span> 
                                    </Text>
                                </Col>
                                <Col span={12}>
                                    <Text
                                        strong
                                        onClick={() =>
                                            handleNavigation('t_branch_id', data.branch_id)
                                        }
                                       
                                    >
                                        Şube:
                                        <span style={{ cursor: 'pointer', color: '#1890ff' ,paddingLeft:'1px'}}>

                                        {data.branch_name}
                                        </span> 
                                    </Text>
                                </Col>
                                <Col span={12}>
                                    <Text
                                        strong
                                        onClick={() =>
                                            handleNavigation('t_user_title_id', data.user_title_id)
                                        }
                                       
                                    >
                                        Unvan:
                                        <span style={{ cursor: 'pointer', color: '#1890ff' ,paddingLeft:'1px'}}>
                                        {data.title_tr_name}
                                        </span> 
                                    </Text>
                                </Col>
                            </Row>
                        </Card>
                        {/* <Card
        title="Yönettiği Birimler"
        extra={<Button>Birime Ata</Button>}

    >
        <List
            itemLayout="horizontal"
            dataSource={managedUnits}
            renderItem={(item) => (
                <List.Item
                    actions={[
                        <Dropdown
                            menu={{ items }}
                            trigger={['click']}
                        >
                            <a onClick={(e) => e.preventDefault()}>
                                <Space>
                                    <MoreOutlined />
                                </Space>
                            </a>
                        </Dropdown>
                    ]}
                >
                    <List.Item.Meta
                        style={{
                            alignItems: 'center'
                        }}
                        avatar={<UserOutlined />}
                        title={<a href="#">{item.name}</a>}
                        description={item.type}
                    />
                </List.Item>
            )}
        />
    </Card> */}
    <Row>
    <Card
   style={{ width: '830px' }}
        // extra={<Button>Çalışan Ata</Button>}
        title="Astları"

    >

        
      <List
            itemLayout="horizontal"
            dataSource={subordinates}
            renderItem={(item) => (
                <List.Item>
                    <List.Item.Meta
                        style={{
                            alignItems: 'center'
                        }}
                        avatar={
                            <>
                                <Link to={`/worker/${item.user_id}`}>
                                    <Avatar
                                        size={24}
                                        style={{
                                            marginRight: '8px'
                                        }}
                                        shape="square"
                                    >
                                        {item.firstname.charAt(0) +
                                            item.lastname.charAt(0)}
                                    </Avatar>
                                </Link>
                            </>
                        }
                        title={
                            <Link to={`/worker/${item.user_id}`}>
                                                    {item.firstname + ' ' + item.lastname}
                                                    </Link>
                        }
                        description={item.title_tr_name}
                    />
                </List.Item>
            )}
        />
      
    </Card> 
    
    </Row>
    
                    </Row>
                </Col>
                <Col lg={10} xs={24}>
                    <Row gutter={[16, 16]}>
                        {/*  <Col span={24}>


            <Card title="Yönetici" extra={<Button>Yönetici Ata</Button>}>
        <List
            itemLayout="horizontal"
            dataSource={[data.manager]}
            renderItem={(item) => (
                <List.Item>
                    <List.Item.Meta
                        style={{
                            alignItems: 'center'
                        }}
                        avatar={
                            <>
                                <Link to={`/worker/${item.id}`}>
                                    <Avatar
                                        size={24}
                                        shape="square"
                                    >
                                        {item.firstname.charAt(0) +
                                            item.lastname.charAt(0)}
                                    </Avatar>
                                </Link>
                            </>
                        }
                        title={
                            <Link to={`/worker/${item.id}`}>
                                {item.fullName || '—'}
                            </Link>
                        }
                        description={item.titleName}
                    />
                </List.Item>
            )}
        />
    </Card>
        </Col>*/}
        <Card style={{ width: '830px' }} title="Üstleri">
            {superiors ?
            
                <List.Item>
                <List.Item.Meta
                    style={{ alignItems: 'center' }}
                    avatar={
                        <Link to={`/worker/${superiors?.user_id}`}>
                            <Avatar
                                size={24}
                                style={{ marginRight: '8px' }}
                                shape="square"
                            >
                                {(superiors?.firstname || '').charAt(0) +
                                    (superiors?.lastname || '').charAt(0)}
                            </Avatar>
                        </Link>
                    }
                    title={
                        <Link to={`/worker/${superiors?.user_id}`}>
                            {superiors?.firstname + ' ' + superiors?.lastname}
                        </Link>
                    }
                    description={superiors?.title_tr_name}
                />
                  </List.Item> 

                 
            : "Üstü Bulunmamaktadır"}
              
           
    
</Card>
                        <Contact data={data} />
                        {/*  <Col span={24}>
             <Card  title="Erişim">
        <List>
            <List.Item>
                <Text>Erişim Tipi:</Text>
                <Text>{data.accessType || '—'}</Text>
            </List.Item>
            <List.Item>
                <Text>Son Giriş Zamanı:</Text>
                <Text>{data.lastLoginDate || '—'}</Text>
            </List.Item>
        </List>
    </Card>
        </Col>*/}
                    </Row>
                </Col>
            </Row>
        </>
    );
};

export default Profile;
