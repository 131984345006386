import { zodResolver } from '@hookform/resolvers/zod';
import { App, Button, Form } from 'antd';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import MyInput from '../../components/MyInput';
import { useLazyQuery } from '../../hooks/useAxios';
import styles from './ForgotPassword.module.css';
const schema = z.object({
    email: z.string().email()
});

const ForgotPassword = () => {
    const { notification } = App.useApp();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const form = useForm({ resolver: zodResolver(schema) });
    const { handleSubmit } = form;
    const { data, loading, error, refetch } = useLazyQuery('/auth/request-reset-password', {
        method: 'post'
    });
    const onSubmit = async (data) => {
        const response = await refetch({ data });
        console.log(response)
        if (response.result == 'success') {
            notification.success({
                message: response.message
            });
            navigate('/sign-in');
        }
    };



    return (
        <FormProvider {...form}>
            <Form onFinish={handleSubmit(onSubmit)} className={styles.form}>
                <MyInput
                    name="email"
                    label={t('ForgotPassword.email')}
                    autoComplete="email"
                />
                <Button type="primary" htmlType="submit" loading={loading}>
                    {t('ForgotPassword.title')}
                </Button>
            </Form>
        </FormProvider>
    );
};

export default ForgotPassword;
