import { Card, Col, Progress, Row } from 'antd';
import React from 'react';
import Address from './components/Address/Address';
import Bank from './components/Bank/Bank';
import Citizenship from './components/Citizenship/Citizenship';
import Education from './components/Education/Education';
import Family from './components/Family/Family';

const PersonalInformation = ({ data }) => {
  console.log(data,"data123")
  return (
    <>
    <title>
    {data.firstname + " " + data.lastname}
    </title>
 <Row gutter={[16, 16]}>
      <Col lg={14} xs={24}>
        <Row gutter={[16, 16]}>
          <Citizenship data={data} />
          <Education data={data} />
          <Family data={data} />
          <Address data={data} />
          <Bank data={data} />
        </Row>
      </Col>
      <Col lg={10}>
        <Card>
          {/* TODO percent will be calculating dynamically */}
          <Progress type="circle" percent={57} />
          <p>Doluluk Oranı</p>
        </Card>
      </Col>
    </Row>
    </>
   
  );
};

export default PersonalInformation;
